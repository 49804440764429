document.addEventListener("DOMContentLoaded", () => {
  document
    .getElementById("contact-form")
    .addEventListener("submit", function (e) {
      e.preventDefault();
      const form = e.target;
      const formData = new FormData(form);
      const messageParagraph = document.getElementById("submitted-message");

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams([...formData]).toString(),
      })
        .then((response) => {
          if (response.ok) {
            messageParagraph.textContent = "Thanks for contacting us!";
            messageParagraph.classList.remove("hidden");
            form.classList.add("hidden");
          } else {
            throw new Error("Submission failed");
          }
        })
        .catch(() => {
          messageParagraph.textContent =
            "There was an error submitting your request. Please try again later.";
          messageParagraph.classList.remove("hidden");
          form.classList.add("hidden");
        });
    });
});
