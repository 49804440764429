import "./vendor/granim";

document.addEventListener("DOMContentLoaded", () => {
  const granimInstance = new Granim({
    element: "#animated-background",
    direction: "diagonal",
    isPausedWhenNotInView: true,
    states: {
      "default-state": {
        gradients: [
          ["#7aaedd", "#b62126"],
          ["#b62126", "#eb9036"],
          ["#ca7465", "#2b292f"],
          ["#ca7465", "#7aaedd"],
        ],
        transitionSpeed: 5000,
      },
    },
  });
});
